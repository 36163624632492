.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: 60px;
}

.section1-text {
  padding: 0 1.5rem;
}

.section1-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;

  max-width: 1300px;
  justify-content: center;

}

/*
.section1-cardtop {
  margin: 2rem;
  align-self: center;
  border: solid 6px transparent;
  border-radius: 10px;
  background-image: linear-gradient(#ffffff, white), 
                    linear-gradient(100deg,#2E60BC,#1d98fd, #54d9fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  flex: 1;
}
*/

.section1-cardtop {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2rem;
  border: solid 6px transparent;
  border-radius: 10px;
  background: rgba( 255, 255, 255, 0.7 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  padding-bottom: 1rem;
  align-items: center;
}

.cutoff-text {
  list-style-type: disclosure-closed;
  font-family: var(--font-family);

  text-align: left;
  padding: 0 1rem 0rem 3rem;
  margin-bottom: 1rem;
  line-height: var(--line-height);

  --max-lines: 5;
  --line-height: 2.25;

  max-height: calc(var(--max-lines) * 1em * var(--line-height));

  overflow: hidden;
}


.expand-btn {
  margin: 0;
  width: 50px;
  appearance: none;
  border: 3px solid #1E3676;
  background: var(--color-primary);
  border-radius: 0.5em;
  cursor: pointer;
  margin-top: 1rem;
  text-align: center;
  color: white;
}

.expand-btn:hover {
  background-color: #1E3676;
}

.expand-btn::before {
  content: "+";
  font-weight: 700;
  font-size: 1.8em;
}

.expand-btn:checked::before {
  content: "-";
}

.cutoff-text:has(+ .expand-btn:checked) {
  max-height: none;
}

@-webkit-keyframes Background {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Background {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Background {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.section1-card p {
  padding: 2rem;
  line-height: 2rem;
  white-space: pre-line;
}

.section1-cardtop img {
  height: 180px;
}

.section1-text {
  padding-bottom: 3rem;
}

.icondiv {
  text-align: center;
  padding: 2rem;
}

h1 {
  margin: 0 2rem;
}

/*
ul.list {
  display: inline-block;
  list-style-type: square;
  font-family: var(--font-family);
  line-height: 2.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  text-align: left;
  padding: 1rem 3rem 2rem 4rem;
}

/*.title {
  background-image:
  linear-gradient(0deg, #4e86e3, #4e86e3 25%, transparent 25%, transparent 75%, #4e86e3 75%),
  linear-gradient(90deg, #4E86E3, #4e86e3 25%, transparent 25%, transparent 75%, #4e86e3 75%),
  linear-gradient(180deg, #4E86E3, #4e86e3 25%, transparent 25%, transparent 75%, #4e86e3 75%),
  linear-gradient(270deg, #4E86E3, #4e86e3 25%, transparent 25%, transparent 75%, #4e86e3 75%)
;
  background-size: 20px 100%, 100% 20px, 20px 100%, 100% 20px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
}*/




@media screen and (max-width: 1050px) {
  .section1-cardtop img {
    height: 140px;
  }

  .section1-cardtop {
    min-width: 300px;
    max-width: 400px;
  }
  }

@media screen and (min-width: 1250px) {
.section1-card {
  padding: 1.5rem 1rem;
}
}

