.section3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: 60px;
}

.section3-content {  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 3rem;
  max-width: 1200px;
}

.section3-card {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 1.5rem 0rem;
  margin: 1rem 2rem;
  background: url("../../assets/bg_hexagon5.jpg");
  background-size: contain;
}

.section3-card p {
  padding: 1rem 3rem;
  text-align: justify;
  text-justify: inter-word;
  font-family: var(--font-family);
}

.section3-card img {
  width: 500px;
  border-radius: 10px;
}

.ban3 {
  background: url("../../assets/ban4.jpg") center no-repeat fixed;
  height: 500px;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .ban3 {
    background: none;
    height: 0;
    }
  }

@media screen and (max-width: 550px) {
  .section3-card {
    margin: 0;
    padding: 0.5rem 0;
  }
  .section3-card p {
    padding: 2rem;
    text-align: justify;
    text-justify: inter-word;
  }
  .section3-card img {
    width: 100%;
    border-radius: 0;
  }
  }

@media screen and (max-width: 1050px) {
  .section3-card {
    flex-wrap: wrap;
  }
  .section3-card p {
    padding-top: 2rem;
  }
  }