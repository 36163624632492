#app-root {
  height: 100vh;
}

.modal-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal--mask {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9998;
}

.modal {
  width: 100%;
}

.modal-window {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  height: 100vh;
  overflow-y: scroll;
  margin: 3rem;
  padding: 0 1rem;
}

@media screen and (max-width: 900px) {
  .modal-window {
  width: 90%;
  }
  }
  

.modal--header,
.modal--footer {
  margin: 0;
  padding: 1rem;
}

.modal--body {
  display: flex;
  justify-content: center;
}

.modal--footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  font-family: var(--font-menu);
  font-size: 1rem;
  line-height: 1.6rem;
}
