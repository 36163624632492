.contacto {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-primary);
  scroll-margin-top: 60px;
}

.contacto-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 4rem;
  max-width: 1600px;
}

.contacto-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-flow: row;
  justify-content: center;
	text-align: left;
  margin: 0 2rem;
  padding-bottom: 2rem;
}

.contacto-icon {
  text-align: center;
}

.contacto-icon > div {
  padding: .5em;
}

.contacto-title {
  color: #111;
}

.mapframe {
  width: 100%;
  height: 400px;
}

.contacto-column p {
  font-size: 1.15rem;
  line-height: 2rem;
}

.contacto-icon h2 {
  color: var(--color-bg);
  line-height: 2.5rem;
}