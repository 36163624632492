.section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: 60px;
}

.section2-content {  

  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.section2-card {

  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: rgba( 255, 255, 255, 0.6);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  text-align: justify;
  text-justify: inter-word;
}

.parallaxban2 {

  display: flex;

  padding: 6rem 0;
  justify-content: center;
}

.ban2 {
  display: flex;
  position: relative;
}