@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=PT+Sans:wght@400;700&display=swap');

:root {
  --font-family: 'PT Sans', sans-serif;
  --font-menu: 'Montserrat', sans-serif;

  --gradient-bar: linear-gradient(270deg, #2E60BC, #1E3676 36%);
  
  --color-bg: #ffffff;
  --color-primary: #4E86E3;
  --color-menu: #2E60BC;
  --color-dark: #1E3676;
  --color-text: #444444;
  --color-text-light: #ffffff;
  --color-opacity: rgba(78, 134, 227, 0.9);
}