.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background: var(--color-menu);
  position: fixed;
  z-index: 2000;
  width: 100%;
}

.navbar a {
  font-family: var(--font-menu);
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.navbar a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.logolink:after {
  display: none;
}

.navbar a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navitem {
  align-items: center;
  padding: 2.5rem 1.25rem;
}

.navbar-menu .navitem {
  padding: 0.7rem;
}

.navbar-links {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navbar-links_logo {
  display: flex;
  background: var(--gradient-bar);
  align-items: center;
  padding: 0;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;
  color: var(--color-text-light);
}

.navbar-img-logo {
  padding: 0.3rem 3rem;
}

.navbar-text-logo h2 {
  font-family: var(--font-family);
  font-weight: 700;
  letter-spacing: 2px;
}

@media screen and (max-width: 550px) {
  .navbar-img-logo { 
  padding: 0.1rem 1rem;
}
}

.navbar-text-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px;
}

.navbar-links_logo img {
  height: 110px;
}

.navbar-links_container {
  display: flex;
}

.navbar-links_container ul {
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 2rem;
  text-transform: capitalize;
  margin: 0 1rem;
  padding: 0;
}

.navbar-menu_container ul {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  list-style-type: none;
  padding: 0;
}

.dropdown ul {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.navbar-menu {
  display: none;
  position: relative;
  z-index: 1;
  padding-right: 10px;
}

.navbar-menu png {
  cursor: pointer;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: end;
  background: var(--color-opacity);
  height: 100vh;
  position: absolute;
  top: 56px;
  right: 0;
  margin-top: 20px;
  min-width: 200px;
  border-radius: 0px;
  z-index: 0;
}

.navbar-menu_container-links {
  display: flex;
  justify-content: flex-start;
  margin-left: 1rem;
}

.navbar-menu_container nav {
  margin: 1rem 0;
}

.dropbtn-main {
  border: none;
  background-color: var(--color-menu);
  cursor: pointer;
}

.dropbtn {
  border: none;
  cursor: pointer;
  width: 40px;
}

.dropbtn:hover,
.dropbtn:focus {
  filter: opacity(0.69);
}

.dropdown {
  display: flex;
  position: absolute;
  text-align: center;
  top: 42px;
  right: 9px;
}


@media screen and (max-width: 1050px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }

  .navitem {
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }

  .dropdown {
    top: 122px;
    right: 50px;
  }

  .dropbtn-main {
    display: flex;
    background-color: var(--color-menu);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: solid transparent 1px;
    align-items: center;
  }
  
  .dropbtn:hover,
  .dropbtn:focus {
    background-color: var(--color-menu);
    color: var(--color-bg);
  }
  
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 0px;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 0px;
  }
  .navbar-links_logo img {
    height: 80px;
  }
  .navbar-menu_container {
    top: 37.5px;
  }
}



.scale-up-top {
	-webkit-animation: scale-up-top 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-top 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-top {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes scale-up-top {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}



.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}



@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
  .navbar-text-logo h2 {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.02em;
  }
}