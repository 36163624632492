.section_inicio {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.inicio-content {
  display: flex;
  max-width: 1200px;
  margin-top: 0;
  margin-bottom: 4rem;
  background: #e4f0fd;
  align-items: center;
  text-align: justify;
  text-justify: inter-word;
  box-shadow: none;
}

@media screen and (min-width: 1200px) {
  .inicio-content {
    box-shadow: 10px 10px 5px -5px #aaaaaa;
    border-radius: 10px;
  }
  .inicio-content img {
    border-radius: 0 10px 10px 0;
  }
  }

@media screen and (min-width: 1050px) {
  .inicio-content img {
    width: 500px;
  }
  }

@media screen and (max-width: 1050px) {
  .inicio-content img {
    width: 300px;
  }
  }

@media screen and (max-width: 800px) {
  .inicio-content {
    background-color: #fff;
    flex-wrap: wrap;
  }
  .inicio-content img {
    width: 100%;
    padding-top: 1rem;
  }
  }

.inicio-text {
  padding: .5rem;
}

.inicio-content p {
  padding: 1rem 3rem;
  line-height: 2.25rem;
}

.inicio-content img {
  display: block;
  max-width: 100%;
  height: auto;
}

.inicio-titulo {
  font-family: var(--font-menu);
  font-size: 1.8rem;
  letter-spacing: 5px;
  text-transform: uppercase;
  background: linear-gradient(to right, #42c5e6 0%, #3a56d3 100%);
  background-clip: content-box;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}